import styled from 'styled-components';

import { font } from '../../../styles/atoms/typography';
import { colors } from '../../../styles/atoms/colors';

export const Container = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  .social-share-heading {
    ${font('text', 'lg', '700')};
    color: ${colors.gray[600]};
  }
`;
export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 24px;
  list-style: none;
  margin: 0px;
  padding: 0px;
`;
export const Button = styled.button`
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background: none;
`;
